<template>
  <v-main>
    <v-container fluid>
      <router-view/>
    </v-container>
  </v-main>
</template>

<script>

export default {
  name: 'template-authorisation',
};
</script>